<template>
  <div class="pt-[57px] md:pt-0">
    <flash-message></flash-message>
    <site-nav v-if="$store.state.showNav" id="main-site-nav" class="print:hidden" @openHelp="openHelp" />

    <div
      v-if="$store.state.showNav"
      class="absolute right-2 top-0 z-60 -mt-8 md:hidden"
      @click.prevent="$store.state.menuExpanded = !$store.state.menuExpanded">
      <IconHamburger class="h-8 w-8" />
    </div>

    <div class="fixed top-0 z-60 flex w-full items-center bg-gray-200 text-center md:hidden" style="height: 57px">
      <IconHamburger
        class="absolute z-50 h-[19px] w-[27px]"
        style="top: 18px; left: 18px"
        v-if="!$store.state.menuExpanded && $store.state.user"
        @click="$store.state.menuExpanded = true" />
      <OpusLogo class="mx-auto" style="width: 87px" />
    </div>

    <div v-if="$store.state.showHeader" class="md:hidden" style="height: 57px"></div>

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" :key="mainRoute" />
      </transition>
    </router-view>

    <confirm-dialogue
      ref="helpWrapper"
      extra-button-text="Frequently Asked Questions"
      @extra="$router.push({ name: 'FAQ' })"
      style="z-index: 70">
      <site-help />
    </confirm-dialogue>
  </div>
</template>

<script>
import FlashMessage from "@/components/utilities/FlashMessage";
import SiteHeader from "@/components/SiteHeader";
import FrontSiteHeader from "@/components/FrontSiteHeader";
import SiteNav from "@/components/navigation/SiteNav";
import SiteFooter from "@/components/SiteFooter";
import ConfirmDialogue from "@/components/utilities/ConfirmDialogue.vue";
import SiteHelp from "@/components/SiteHelp.vue";
import OpusLogo from "@/components/OpusLogo.vue";

export default {
  name: "App",
  components: {
    FlashMessage,
    SiteNav,
    SiteHeader,
    FrontSiteHeader,
    SiteFooter,
    ConfirmDialogue,
    SiteHelp,
    OpusLogo
  },
  computed: {
    mainRoute() {
      //return the first segment of the route path
      return this.$route.path.split("/")[1];
    }
  },
  methods: {
    componentKey(route) {
      /* when in an org's member page, navigating to the licences page doesn't update unless the key is changed */
      if (route.fullPath.includes("my-courses")) {
        return "my_courses";
      }
      return route.fullPath;
    },
    openHelp() {
      this.$refs.helpWrapper
        .show({
          title: undefined,
          message: undefined,
          okButton: "Close Help",
          hideCancelButton: true,
          largeWindow: true
        })
        .then(() => {})
        .catch(() => {});
    }
  }
};
</script>

<style>
body {
  overflow-y: scroll;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
  transition: all 100ms ease;
}

.fade-leave-active {
  transition: all 100ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slow-fade-enter-active {
  transition: all 300ms ease;
}

.slow-fade-leave-active {
  transition: all 300ms ease;
}

.slow-fade-enter-from,
.slow-fade-leave-to {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.quick-fade-enter-active {
  transition: all 50ms ease;
}

.quick-fade-leave-active {
  transition: all 50ms ease;
}

.quick-fade-enter-from,
.quick-fade-leave-to {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.vslide-enter-active {
  transition: all 300ms ease-in-out;
}

.vslide-leave-active {
  transition: all 300ms ease-in-out;
}

.vslide-enter-from,
.vslide-leave-to {
  transform: translateY(-100%);
}
</style>
