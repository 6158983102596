import auth from "../../middleware/auth.js";
const parseUrlParamToInt = (route, param) => {
  let value = Number.parseInt(route.params[param], 10);
  if (Number.isNaN(value)) {
    value = null;
  }

  return value;
};

export default [
  {
    path: "",
    name: "SiteAdminBookingSystemDefaultChild",
    redirect: {
      name: "SiteAdminBookingSystemProjects"
    }
  },
  {
    path: "projects",
    name: "SiteAdminBookingSystemProjects",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/projects/Projects.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/new",
    name: "SiteAdminBookingSystemNewProject",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/projects/NewProject.vue"),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/edit",
    name: "SiteAdminBookingSystemEditProject",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/projects/EditProject.vue"),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let projectId = parseUrlParamToInt(route, "project_id");

      return {
        projectId
      };
    }
  },
  {
    path: "projects/:project_id/project-requests/type-choice",
    name: "SiteAdminBookingSystemNewProjectRequestTypeChoice",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/NewProjectRequestTypeChoice.vue"
      ),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/project-requests/new",
    name: "SiteAdminBookingSystemNewProjectRequest",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/NewStandaloneProjectRequest.vue"
      ),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/multi-day-project-requests/new",
    name: "SiteAdminBookingSystemNewMultiDayProjectRequest",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/NewMultiDayProjectRequest.vue"
      ),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/two-in-one-day-project-requests/new",
    name: "SiteAdminBookingSystemNewTwoInOneDayProjectRequest",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/NewTwoInOneDayProjectRequest.vue"
      ),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/project-requests/:project_request_id/edit",
    name: "SiteAdminBookingSystemEditProjectRequest",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/EditProjectRequest.vue"
      ),
    meta: {
      middleware: auth
    }
  },
  {
    path: "projects/:project_id/two-in-one-day/:two_in_one_day_id/manage",
    name: "SiteAdminBookingSystemManageTwoInOneDay",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/ManageTwoInOneDay.vue"
      ),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let projectId = parseUrlParamToInt(route, "project_id");
      let twoInOneDayId = parseUrlParamToInt(route, "two_in_one_day_id");

      return {
        projectId,
        twoInOneDayId
      };
    }
  },
  {
    path: "projects/:project_id/multi-day/:multi_day_id/manage",
    name: "SiteAdminBookingSystemManageMultiDay",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/ManageMultiDay.vue"
      ),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let projectId = parseUrlParamToInt(route, "project_id");
      let multiDayId = parseUrlParamToInt(route, "multi_day_id");

      return {
        projectId,
        multiDayId
      };
    }
  },
  {
    //how to make :edit optional?
    path: "projects/:project_id/multi-day-project-requests/:multi_day_id/select-trainer/:edit?",
    name: "SiteAdminBookingSystemSelectMultiDayTrainer",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/SelectMultiDayTrainer.vue"
      ),
    meta: {
      middleware: auth
    },
    props: (route) => {
      let projectId = parseUrlParamToInt(route, "project_id");
      let multiDayId = parseUrlParamToInt(route, "multi_day_id");
      let edit = route.params.edit ? true : false;

      return {
        projectId,
        multiDayId,
        edit
      };
    }
  },
  {
    path: "projects/:project_id/project-requests/:project_request_id/manage",
    name: "SiteAdminBookingSystemManageProjectRequest",
    component: () =>
      import(
        /* webpackChunkName: "siteAdminBookings" */
        "@/views/site_admin/booking_system/projects/ManageProjectRequest.vue"
      ),
    meta: {
      middleware: auth
    }
  },
  {
    path: "calendars",
    name: "SiteAdminBookingSystemCalendars",
    component: () =>
      import(/* webpackChunkName: "siteAdminBookings" */ "@/views/site_admin/booking_system/calendars/Calendars.vue"),
    meta: {
      middleware: auth
    }
  }
];
